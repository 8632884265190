import { DateTime } from 'luxon';

export function dateFormatter(date: DateTime, locale: string): string {
  return date.toFormat('ccc. dd MMMM', { locale });
}

export function dateTimeRangeFormatter(start: DateTime, end: DateTime, locale: string): string {
  const startDay = start.toFormat('ccc. dd MMMM', { locale });
  const endDay = end.toFormat('ccc. dd MMMM', { locale });

  return startDay === endDay
    ? `${startDay} ${start.toFormat('T')} – ${end.toFormat('T')}`
    : `${startDay} ${start.toFormat('T')} – ${endDay} ${end.toFormat('T')}`;
}

export function priceFormatter(price: number, locale: string, currency: string): string {
  return price.toLocaleString(locale, {
    style: 'currency',
    maximumFractionDigits: 0,
    currency,
  });
}
