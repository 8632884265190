import './style.scss';

import React from 'react';

export default function Spinner(): JSX.Element {
  return (
    <div className="spinner">
      <svg className="spinner__circle" viewBox="25 25 50 50">
        <circle className="spinner__path" cx="50" cy="50" r="20" fill="none" stroke="#444" strokeWidth="3" />
      </svg>
    </div>
  );
}
