import './style.scss';

import React, { useContext } from 'react';
import { LineItem, SubmittedOrderData, OrderOption } from '../../../modules/types';
import { CreditCard, CheckCircle, Clock, Users } from 'react-feather';
import { DateTime } from 'luxon';

import { GlobalContext } from '../../../context/GlobalContext';

type Props = {
  order: SubmittedOrderData;
  paymentUrl: string;
};

export default function OrderConfirmation({ order, paymentUrl }: Props): JSX.Element {
  const { units, settings, formatDateTimeRange, formatPrice } = useContext(GlobalContext);

  function option(option: OrderOption, key: string) {
    let price: JSX.Element;

    if (option.option?.price && option.option?.price.value > 0) {
      price = <>{formatPrice(option.option.price)}</>;
    } else {
      price = <>безкоштовно</>;
    }
    return (
      <div className="option" key={key}>
        <span className="option__content">
          <span className="option__name">{option.option?.name}</span>
        </span>
        <span className="option__price">{price}</span>
      </div>
    );
  }

  function lineItemsList(items: LineItem[]) {
    const lineItems: JSX.Element[] = [];
    items.forEach((item) => {
      const from = DateTime.fromISO(item.start);
      const to = DateTime.fromISO(item.start).plus({ hour: 1, seconds: 1 });
      lineItems.push(
        <div className="order-confirmation__line-item" key={`${item.unitId}__${item.start}`}>
          <div className="order-confirmation__line-item-title">
            <span>{formatDateTimeRange(from, to)}</span>
          </div>
          <div className="order-confirmation__line-item-price">{formatPrice({ value: item.price || 0 })}</div>
          {item.options && (
            <div className="options order-confirmation__line-item-options">
              {item.options.map((o) => option(o, `${item.unitId}_${from.toMillis()}_${o.id}`))}
            </div>
          )}
        </div>,
      );
    });

    return lineItems;
  }

  const orderUnits: Array<JSX.Element> = [];

  const orderHasEarlyOrLateSlot =
    settings?.schedule &&
    order.lineItems.filter((item) => {
      const from = DateTime.fromISO(item.start);
      return from.hour < settings.schedule.from || from.hour >= settings.schedule.to;
    }).length > 0;

  units?.forEach((unit) => {
    const unitLineItems = order.lineItems
      .filter((item) => item.unitId == unit.id)
      .sort((a, b) => DateTime.fromISO(a.start).diff(DateTime.fromISO(b.start)).toMillis());
    const unitOptions = order.options?.filter((o) => o.option?.unitIds?.includes(unit.id));

    if (unitLineItems.length > 0) {
      orderUnits.push(
        <div className="order-confirmation__unit" key={`${unit.id}-unit-wrapper`}>
          <div className="order-confirmation__unit-title" key={`${unit.id}-unit-title`}>
            {unit.name}
          </div>
          {lineItemsList(unitLineItems)}
          {unitOptions && (
            <div className="options order-confirmation__options">
              {unitOptions.map((o) => option(o, `${unit.id}_${o.id}`))}
            </div>
          )}
        </div>,
      );
    }
  });

  return (
    <div className="order-confirmation">
      <div className="order-confirmation__title">
        <CheckCircle className="order-confirmation__icon" />
        Ви зробили замовлення! <span className="order-confirmation__number">Номер замовлення — {order.id}</span>
        <br />
        <small>Деталі замовлення {order.total > 0 && <>та посилання для сплати</>} ми відправили вам на пошту</small>
      </div>

      <div className="order-confirmation__pane-1">
        {orderUnits}
        <div className="order-confirmation__total">
          {settings?.visitors.message && (
            <div className="cart__message cart__message--visitors">
              <Users className="cart__message-icon" />
              <div className="cart__message-text" dangerouslySetInnerHTML={{ __html: settings.visitors.message }} />
            </div>
          )}
          {settings?.schedule.message && orderHasEarlyOrLateSlot && (
            <div className="cart__message cart__message--time">
              <Clock className="cart__message-icon" />
              <div className="cart__message-text" dangerouslySetInnerHTML={{ __html: settings.schedule.message }} />
            </div>
          )}
          {order.coupon && (
            <div className="order-confirmation__payed">
              Сплачено сертифікатом: {formatPrice({ value: order.grossTotal - order.total })}
            </div>
          )}
          {!!order.total && order.total > 0 && (
            <div className="order-confirmation__to-pay">До сплати: {formatPrice({ value: order.total })}*</div>
          )}
        </div>
      </div>

      <div className="order-confirmation__pane-2">
        <div className="order-confirmation__info">
          <div className="order-confirmation__subtitle">Ваші дані</div>

          <div className="order-confirmation__info-line">
            <strong>Ім&apos;я:</strong> {order.firstName} {order.lastName}
          </div>
          <div className="order-confirmation__info-line">
            <strong>Телефон:</strong> {order.phone}
          </div>
          <div className="order-confirmation__info-line">
            <strong>Email:</strong> {order.email}
          </div>
          {order.coupon && (
            <div className="order-confirmation__info-line">
              <strong>Подарунковий сертифікат:</strong> {order.coupon.code}
            </div>
          )}
          {order.comment && (
            <div className="order-confirmation__info-line">
              <strong>Додаткові побажання:</strong> <p>{order.comment}</p>
            </div>
          )}
        </div>
        <div className="order-confirmation__note">
          {settings?.expiration.message && !!order.total && order.total > 0 && (
            <>
              <ul>
                <li dangerouslySetInnerHTML={{ __html: settings.expiration.message }} />
              </ul>
            </>
          )}
          {order.coupon && (
            <ul>
              <li>Обов&apos;язково візьміть подарунковий сертифікат із собою у студію.</li>
            </ul>
          )}
        </div>
        {!!order.total && order.total > 0 && settings?.onlinePaymentsEnabled && (
          <div>
            <div className="order-confirmation__actions">
              <a className="order-confirmation__pay button" href={paymentUrl}>
                <CreditCard className="order-confirmation__pay-icon" />
                <span className="order-confirmation__pay-label">Сплатити онлайн</span>
              </a>
            </div>
            <small className="order-confirmation__fee">*При онлайн оплаті стягується комісія</small>
          </div>
        )}
      </div>
    </div>
  );
}
