import './style.scss';

import React, { useEffect, ReactNode } from 'react';
import { X } from 'react-feather';

type Props = {
  children: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
};

export default function Modal(props: Props): JSX.Element {
  useEffect(() => {
    props.onOpen && props.onOpen();
  }, []);

  return (
    <div className="modal">
      <div className="modal__overlay" />
      <div className="modal__window">
        <button className="modal__close" onClick={props.onClose}>
          <X />
        </button>
        {props.children}
      </div>
    </div>
  );
}
