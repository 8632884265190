import React, { useContext } from 'react';
import classNames from 'classnames';

import { AlertCircle } from 'react-feather';

import { OptionsList } from '../../../context/OrderContext';
import { GlobalContext } from '../../../context/GlobalContext';
import { Option, OptionType, Price, Slot } from '../../../modules/types';
import Autolink from './Autolink';

type Props = {
  parentId: string | number;
  itemOptions: OptionsList;
  options: Option[];
  optionsSlots?: Map<Option, Slot[]>;
  className?: string;
  optionErrors: { slotId: string; optionId: number }[];
  onToggleOption: (option: Option, value: string | boolean) => void;
};

export default function CartOptions({
  options,
  optionErrors,
  itemOptions,
  optionsSlots,
  className,
  parentId,
  onToggleOption,
}: Props): JSX.Element {
  const { formatPrice } = useContext(GlobalContext);

  const optionsList: Array<JSX.Element> = [];

  function optionControl(option: Option, selected: boolean, errored: boolean): JSX.Element {
    switch (option.type) {
      case OptionType.Checkbox:
        return (
          <input
            type="checkbox"
            className="form-checkbox"
            checked={selected}
            onChange={() => onToggleOption(option, true)}
          />
        );
      case OptionType.Mandatory:
        return <input type="checkbox" className="form-checkbox" disabled={true} checked={true} />;
      case OptionType.Slot:
        const isSlotAvalible = optionsSlots?.get(option)?.length;
        return (
          <input
            type="checkbox"
            className="form-checkbox"
            disabled={!isSlotAvalible && !errored}
            checked={selected}
            onChange={() => onToggleOption(option, true)}
          />
        );
    }
    return <></>;
  }

  function optionPrice(price: Price, selected: boolean): JSX.Element {
    if (price.value > 0) {
      return (
        <>
          {!selected && <>+</>}
          {formatPrice(price)}
        </>
      );
    } else {
      return <>безкоштовно</>;
    }
  }

  options.forEach((option) => {
    const selected = itemOptions.has(option);
    const errored = typeof optionErrors.find((e) => e.optionId === option.id) !== 'undefined';

    if (option.type !== OptionType.Mandatory || (option.type === OptionType.Mandatory && selected)) {
      optionsList.push(
        <li className="cart__option" key={`${parentId}_${option.id}`}>
          <label className={classNames('option', { 'option--error': errored })}>
            {errored && <AlertCircle className="option__error-icon" />}
            {optionControl(option, selected, errored)}
            <span className="option__content">
              <span className="option__name">
                <span>{option.name}</span>
              </span>
              <span className="option__description">
                <Autolink>{option.description}</Autolink>
              </span>
            </span>
            <span className="option__price">{optionPrice(option.price, selected)}</span>
          </label>
        </li>,
      );
    }
  });

  return (
    <div className={classNames('cart__options', className)}>
      <ul>{optionsList}</ul>
    </div>
  );
}
