import React from 'react';
import Autolinker from 'autolinker';

interface Props {
  children: string;
}

export default function Autolink({ children }: Props): JSX.Element {
  return <span dangerouslySetInnerHTML={{ __html: Autolinker.link(children) }} />;
}
