import React, { createContext, ReactNode, useState } from 'react';
import {
  Discount,
  DiscountConditionType,
  DiscountItemsCountCondition,
  Option,
  Price,
  Slot,
  Unit,
} from '../modules/types';

function checkItemsCount(condition: DiscountItemsCountCondition, slot: Slot, lineItems: LineItems): boolean {
  const validLineItems = Array.from(lineItems.keys()).filter((lineItem) => condition.units.includes(lineItem.unitId));
  return validLineItems.length >= condition.count;
}

const discountConditionsProcessors = {
  [DiscountConditionType.OrderItemsCount]: checkItemsCount,
};

export type OptionsList = Map<Option, string | boolean>;
export type LineItems = Map<Slot, OptionsList>;

type OrderContext = {
  lineItems: LineItems;
  orderOptions: OptionsList;
  clearOrder: () => void;
  getLineItemsByUnit: (unit: Unit) => LineItems;
  getSlotPrice: (slot: Slot) => Price;
  getSlotsPrice: (slots: LineItems) => number;
  getTotalPrice: () => number;
  getOptionsPrice: (options: OptionsList) => number;
  toggleSlot: (slot: Slot, options?: OptionsList) => void;
  hasSlot: (slot: Slot) => boolean;
  addSlotOption: (slot: Slot, option: Option, value: string | boolean) => void;
  removeSlotOption: (slot: Slot, option: Option) => void;
  toggleSlotOption: (slot: Slot, option: Option, value?: string | boolean) => void;
  addOrderOption: (option: Option, value: string | boolean) => void;
  removeOrderOption: (option: Option) => void;
  toggleOrderOption: (option: Option, value?: string | boolean) => void;
};
export const OrderContext = createContext<OrderContext>({} as OrderContext);

type Props = {
  children: ReactNode;
  discounts: Discount[];
};

export default function OrderProvider({ discounts, children }: Props): JSX.Element {
  const [lineItems, setLineItems] = useState<LineItems>(new Map());
  const [orderOptions, setOrderOptions] = useState<OptionsList>(new Map());

  function toggleSlot(slot: Slot, options: OptionsList = new Map()): void {
    const existingSlot = Array.from(lineItems.keys()).find((s) => s.id === slot.id);
    existingSlot ? lineItems.delete(existingSlot) : lineItems.set(slot, options);

    setLineItems(new Map(lineItems));
  }

  function hasSlot(slot: Slot): boolean {
    return Array.from(lineItems.keys()).some((s) => s.id === slot.id);
  }

  function addOrderOption(option: Option, value: string | boolean): void {
    setOrderOptions(new Map(orderOptions.set(option, value)));
  }

  function removeOrderOption(option: Option): void {
    if (orderOptions.delete(option)) {
      setOrderOptions(new Map(orderOptions));
    }
  }

  function toggleOrderOption(option: Option, value?: string | boolean): void {
    if (orderOptions.has(option)) {
      removeOrderOption(option);
    } else if (value) {
      addOrderOption(option, value);
    }
  }

  function addSlotOption(slot: Slot, option: Option, value: string | boolean): void {
    const options = lineItems.get(slot);

    if (options) {
      options.set(option, value);
      setLineItems(new Map(lineItems.set(slot, options)));
    }
  }

  function removeSlotOption(slot: Slot, option: Option): void {
    const options = lineItems.get(slot);

    if (options && options.delete(option)) {
      setLineItems(new Map(lineItems.set(slot, options)));
    }
  }

  function toggleSlotOption(slot: Slot, option: Option, value?: string | boolean): void {
    const options = lineItems.get(slot);

    if (options && options.has(option)) {
      removeSlotOption(slot, option);
    } else if (options && value) {
      addSlotOption(slot, option, value);
    }
  }

  function priceWithDiscount(price: Price): number {
    return price.value - (price.discount !== undefined ? price.value * (price.discount / 100) : 0);
  }

  function getOptionsPrice(options: OptionsList): number {
    return Array.from(options).reduce((sum: number, [option]) => {
      return sum + priceWithDiscount(option.price);
    }, 0);
  }

  function isDiscountConditionsMet(slot: Slot, discount: Discount): boolean {
    return discount.rules.conditions.reduce<boolean>((valid, condition) => {
      const isConditionMet = discountConditionsProcessors[condition.type]?.(condition, slot, lineItems) ?? false;
      return valid && isConditionMet;
    }, true);
  }

  function calculateDiscount(slot: Slot, discount: Discount): number {
    if (!discount.units.includes(slot.unitId)) {
      return 0;
    }
    return isDiscountConditionsMet(slot, discount) ? discount.rules.value : 0;
  }

  function getSlotDiscount(slot: Slot): number {
    const initialDiscount = slot.price.discount ?? 0;
    return discounts.reduce((finalDiscount, discount) => {
      return Math.max(finalDiscount, calculateDiscount(slot, discount));
    }, initialDiscount);
  }

  function getSlotPrice(slot: Slot): Price {
    const finalDiscount = getSlotDiscount(slot);
    return {
      value: slot.price.value,
      discount: finalDiscount,
    };
  }

  function slotsPrice(slots?: LineItems): number {
    const items = slots ? Array.from(slots) : Array.from(lineItems);
    return items.reduce((sum: number, [slot, options]) => {
      return sum + priceWithDiscount(getSlotPrice(slot)) + getOptionsPrice(options);
    }, 0);
  }

  function getTotalPrice(): number {
    return slotsPrice() + getOptionsPrice(orderOptions);
  }

  function getLineItemsByUnit(unit: Unit): LineItems {
    return new Map(
      Array.from(lineItems)
        .filter(([slot]) => slot.unitId == unit.id)
        .sort(([a], [b]) => a.from.diff(b.from).toMillis()),
    );
  }

  function clearOrder() {
    setLineItems(new Map());
    setOrderOptions(new Map());
  }

  return (
    <OrderContext.Provider
      value={{
        lineItems,
        orderOptions,
        clearOrder,
        getLineItemsByUnit,
        getSlotPrice,
        getSlotsPrice: slotsPrice,
        getTotalPrice,
        getOptionsPrice,
        toggleSlot,
        hasSlot,
        addSlotOption,
        removeSlotOption,
        toggleSlotOption,
        addOrderOption,
        removeOrderOption,
        toggleOrderOption,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}
