import './style.scss';

import React, { useContext } from 'react';
import plural from 'plural-ru';
import classNames from 'classnames';
import { ShoppingBag } from 'react-feather';

import { OrderContext } from '../../context/OrderContext';
import { GlobalContext } from '../../context/GlobalContext';

type Props = {
  className?: string;
  onCheckout: () => void;
};

export default function MiniCart({ className, onCheckout }: Props): JSX.Element {
  const { lineItems, getTotalPrice } = useContext(OrderContext);
  const { formatPrice } = useContext(GlobalContext);

  function cartContent() {
    if (lineItems.size > 0) {
      return (
        <>
          <div className="mini-cart__content">
            <div className="mini-cart__count">{plural(lineItems.size, '%d година', '%d години', '%d годин')}</div>
            <div className="mini-cart__total">{formatPrice({ value: getTotalPrice() })}</div>
          </div>
          <div className="mini-cart__actions">
            <button className="button mini-cart__checkout">Оформити</button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="mini-cart__content">
            <div className="mini-cart__message">Нічого не обрано</div>
          </div>
        </>
      );
    }
  }

  return (
    <div
      className={classNames('mini-cart', className, {
        'mini-cart--active': lineItems.size > 0,
      })}
      onClick={() => lineItems.size > 0 && onCheckout()}
    >
      <ShoppingBag className="mini-cart__icon" />
      {cartContent()}
    </div>
  );
}
