import './style.scss';

import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { GlobalContext } from '../../../context/GlobalContext';
import { Slot as SlotData } from '../../../modules/types';

import DaySlot from './DaySlot';

type Props = {
  date: DateTime;
  slots?: SlotData[];
  className?: string;
};

export default function Day({ date, slots, className }: Props): JSX.Element {
  const { settings } = useContext(GlobalContext);
  return (
    <div
      className={classNames(className, 'day', { 'day--past': date.startOf('day') < DateTime.now().startOf('day') })}
      key={date.toFormat('yyyy-MM-dd')}
    >
      <div className="day__head day-head">
        <div className="day-head__day">{date.toLocaleString({ weekday: 'short', locale: settings?.locale })}</div>
        <div className="day-head__date">{date.get('day')}</div>
      </div>
      {slots && slots.map((slot) => <DaySlot key={slot.id} slot={slot} />)}
    </div>
  );
}
