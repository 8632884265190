import { DateTime } from 'luxon';

export enum apiMethod {
  get = 'GET',
  post = 'POST',
}

export enum ErrorType {
  SlotPast = 'SLOT_PAST',
  SlotUnavailable = 'SLOT_UNAVAILABLE',
  OptionUnavailable = 'OPTION_UNAVAILABLE',
  CouponUnavailable = 'COUPON_UNAVAILABLE',
}

export type Error = {
  error: ErrorType;
  unitId?: number;
  start?: string;
  optionId?: number;
};

export enum SlotStatus {
  Booked = 'BOOKED',
  Available = 'AVAILABLE',
  Reserved = 'RESERVED',
  NotAvailable = 'NOTAVAILABLE',
}

export enum OptionType {
  Checkbox = 'CHECKBOX',
  Number = 'NUMBER',
  Text = 'TEXT',
  Slot = 'SLOT',
  Mandatory = 'MANDATORY',
  Unit = 'UNIT',
}

export enum UnitType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Units = 'UNITS',
}

export type Price = {
  value: number;
  discount?: number;
};

export type Slot = {
  id: string;
  count?: number;
  unitId: number;
  status: SlotStatus;
  from: DateTime;
  to: DateTime;
  price: Price;
};

export type Unit = {
  id: number;
  name: string;
  slug: string;
  type: UnitType;
  opensAt: string;
  image: string;
};

export type slotFilters = {
  unitId: number;
  from?: DateTime;
  to?: DateTime;
};

export type OptionExtra = {
  unitId: number;
};

export type Option = {
  id: number;
  unitIds?: number[];
  perSlot: boolean;
  name: string;
  description: string;
  type: OptionType;
  price: Price;
  extra: OptionExtra;
};

export type OrderOption = {
  id: number;
  value: string | boolean;
  option?: Option;
};

export type LineItem = {
  unitId: number;
  start: string;
  price?: number;
  options?: OrderOption[];
};

export type CouponData = {
  code: string;
  amount: number;
};

export type OrderData = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  comment: string;
  couponCode?: string;
  options?: OrderOption[];
  lineItems: LineItem[];
};

export type SubmittedOrderData = {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  comment: string;
  coupon?: CouponData;
  lineItems: LineItem[];
  options?: OrderOption[];
  total: number;
  grossTotal: number;
  expiresAt: DateTime;
};

export type Settings = {
  currency: string;
  locale: string;
  timezone: string;
  url: string;
  rules: string;
  phone: string;
  logo: string;
  cleanPhone: string;
  schedule: {
    from: number;
    to: number;
    message: string;
  };
  visitors: {
    message: string;
  };
  expiration: {
    message: string;
  };
  globalMessage: string;
  onlinePaymentsEnabled: boolean;
  couponsEnabled: boolean;
};

export enum DiscountType {
  Percentage = 'percentage',
}

export enum DiscountConditionType {
  OrderItemsCount = 'order_items_count',
}

interface DiscountCondition {
  type: DiscountConditionType;
}

export interface DiscountItemsCountCondition extends DiscountCondition {
  type: DiscountConditionType.OrderItemsCount;
  count: number;
  units: number[];
}

type DiscountConditionTypes = DiscountItemsCountCondition;

export type Discount = {
  units: number[];
  rules: {
    type: DiscountType;
    value: number;
    conditions: DiscountConditionTypes[];
  };
};
