import { DateTime } from 'luxon';
import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { loadDiscounts, loadOptions, loadSettings, loadUnits } from '../modules/api';
import { Discount, Option, Price, Settings, Unit } from '../modules/types';
import { dateFormatter, dateTimeRangeFormatter, priceFormatter } from '../modules/formatters';
import { Settings as LuxonSettings } from 'luxon';

type Props = {
  children: ReactNode;
};

type GlobalContext = {
  units?: Unit[];
  options: Option[];
  settings?: Settings;
  discounts: Discount[];
  formatDate: (date: DateTime) => string;
  formatDateTimeRange: (start: DateTime, end: DateTime) => string;
  formatPrice: (price: Price) => string;
};
export const GlobalContext = createContext<GlobalContext>({} as GlobalContext);

export default function GlobalProvider({ children }: Props): JSX.Element {
  const [settings, setSettings] = useState<Settings>();
  useEffect(() => {
    loadSettings(setSettings);
  }, []);

  const [discounts, setDiscounts] = useState<Discount[]>([]);
  useEffect(() => {
    loadDiscounts(setDiscounts);
  }, []);

  const [options, setOptions] = useState<Option[]>([]);
  useEffect(() => {
    loadOptions(setOptions);
  }, []);

  const [units, setUnits] = useState<Unit[]>();
  useEffect(() => {
    loadUnits(setUnits);
  }, []);

  useEffect(() => {
    if (settings?.timezone) {
      LuxonSettings.defaultZoneName = settings.timezone;
    }
  }, [settings?.timezone]);

  const formatDate = useCallback(
    (date: DateTime): string => {
      if (!settings) {
        return '';
      }
      return dateFormatter(date, settings.locale);
    },
    [settings?.locale],
  );
  const formatDateTimeRange = useCallback(
    (start: DateTime, end: DateTime): string => {
      if (!settings) {
        return '';
      }
      return dateTimeRangeFormatter(start, end, settings.locale);
    },
    [settings?.locale],
  );
  const formatPrice = useCallback(
    (price: Price): string => {
      if (!settings) {
        return '';
      }
      const discount = price.discount ? (price.value * price.discount) / 100 : 0;
      return priceFormatter(price.value - discount, settings.locale, settings.currency);
    },
    [settings?.locale, settings?.currency],
  );

  return (
    <GlobalContext.Provider
      value={{
        units,
        options,
        settings,
        discounts,
        formatDate,
        formatDateTimeRange,
        formatPrice,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
