import './style.scss';

import React, { useContext, useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { ArrowLeftCircle, Info, Phone, X } from 'react-feather';
import ReactGA from 'react-ga4';

import { Unit, UnitType } from '../../modules/types';
import OrderProvider from '../../context/OrderContext';

import UnitSelector from '../UnitSelector';
import Calendar from '../Calendar';
import MiniCart from '../MiniCart';
import Modal from '../Modal';
import Checkout from '../Checkout';
import Spinner from '../Spinner/index';
import { GlobalContext } from '../../context/GlobalContext';

export default function App(): JSX.Element {
  const history = useHistory();
  const [isMessageCollapsed, setIsMessageCollapsed] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: history.location.pathname });
  }, [history.location.pathname]);

  const routerMatchParams = useRouteMatch({ path: '/:slug/:year?/:week?' })?.params;

  const routeSlug = (function (routerMatchParams: { slug?: string } | undefined): string {
    if (routerMatchParams && routerMatchParams.slug) {
      return routerMatchParams.slug;
    }
    return '';
  })(routerMatchParams);

  const routeYear = (function (routerMatchParams: { year?: string } | undefined): string | undefined {
    if (routerMatchParams && routerMatchParams.year) {
      return routerMatchParams.year;
    }
    return undefined;
  })(routerMatchParams);

  const routeWeek = (function (routerMatchParams: { week?: string } | undefined): string | undefined {
    if (routerMatchParams && routerMatchParams.week) {
      return routerMatchParams.week;
    }
    return undefined;
  })(routerMatchParams);

  const { units, settings, discounts } = useContext(GlobalContext);
  const [unit, setUnit] = useState<Unit>();

  useEffect(() => {
    if (units?.length) {
      const currentUnit = units?.find((u) => u.slug === routeSlug);
      if (currentUnit) {
        setUnit(currentUnit);
      }
    }
  }, [units]);

  const [isCheckoutVisible, setIsCheckoutVisible] = useState<boolean>(false);
  const onShowCheckout = () => {
    document.body.classList.add('is--modal-open');
  };
  const onHideCheckout = () => {
    document.body.classList.remove('is--modal-open');
    setIsCheckoutVisible(false);
  };

  if (!settings) {
    return (
      <div className="loading loading--global">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="app">
      <header className="app__header">
        <a className="app__back" href={settings.url}>
          <ArrowLeftCircle className="app__back-icon" />
          До сайту
        </a>
        <img className="app__logo" src={settings.logo} alt="" />
        <a href={`tel:${settings.cleanPhone}`} className="app__phone">
          <Phone className="app__phone-icon" />
          {settings.phone}
        </a>
      </header>
      <div className="container">
        {settings.globalMessage && !isMessageCollapsed && (
          <div className="app__message app__message--testing">
            <Info className="app__message-icon" />
            <div className="app__message-text" dangerouslySetInnerHTML={{ __html: settings.globalMessage }} />
            <X className="app__message-close" onClick={() => setIsMessageCollapsed(true)} />
          </div>
        )}
        <OrderProvider discounts={discounts}>
          {units && (
            <div className="app__wrapper">
              <UnitSelector
                units={units.filter((u) => u.type === UnitType.Primary)}
                unit={unit}
                onSelect={(unit) => {
                  setUnit(unit);
                  if (routeYear && routeWeek) {
                    history.push(`/${unit.slug}/${routeYear}/${routeWeek}`);
                  } else {
                    history.push(`/${unit.slug}`);
                  }
                }}
                className={classNames('app__selector', {
                  'app__selector--compact': unit,
                })}
              />
              {unit && (
                <Switch>
                  <Route path="/:slug/:year?/:week?">
                    <MiniCart onCheckout={() => setIsCheckoutVisible(true)} className="app__mini-cart" />
                    <Calendar unit={unit} />
                  </Route>
                </Switch>
              )}
            </div>
          )}
          {!units && (
            <div className="loading loading--units">
              <Spinner />
            </div>
          )}
          {isCheckoutVisible && (
            <Modal onOpen={onShowCheckout} onClose={onHideCheckout}>
              <Checkout />
            </Modal>
          )}
        </OrderProvider>
      </div>
    </div>
  );
}
