import React from 'react';
import classNames from 'classnames';
import { Unit } from '../../modules/types';
import './style.scss';

type Props = {
  unit?: Unit;
  units: Unit[];
  className?: string;
  onSelect: (unit: Unit) => void;
};

function UnitSelector({ onSelect, className, unit, units }: Props): JSX.Element {
  return (
    <nav className={classNames(className, 'unit-selector')}>
      {units.map((u) => (
        <div
          className={classNames(`unit-selector__unit unit unit--${u.slug}`, {
            'unit--other': unit && unit.slug !== u.slug,
            'unit--active': unit && unit.slug === u.slug,
          })}
          key={u.slug}
          onClick={() => onSelect(u)}
        >
          <span className="unit__image" style={{ backgroundImage: `url(${u.image})` }} />
          <span className="unit__name">{u.name}</span>
        </div>
      ))}
    </nav>
  );
}

export default UnitSelector;
