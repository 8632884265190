import React, { useState } from 'react';
import { SubmittedOrderData } from '../../modules/types';

import Cart from './Cart';
import OrderConfirmation from './OrderConfirmation';

export default function Checkout(): JSX.Element {
  const [submittedOrder, setSubmittedOrder] = useState<SubmittedOrderData>();
  const [paymentUrl, setPaymentUrl] = useState<string>('');

  return (
    <>
      {!submittedOrder && (
        <Cart
          showConfirmation={(order, url) => {
            setSubmittedOrder(order);
            setPaymentUrl(url);
          }}
        />
      )}
      {submittedOrder && <OrderConfirmation order={submittedOrder} paymentUrl={paymentUrl} />}
    </>
  );
}
