import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Trash2, ChevronDown, AlertCircle } from 'react-feather';

import CartOptions from './Options';

import { LineItems, OrderContext } from '../../../context/OrderContext';
import { GlobalContext } from '../../../context/GlobalContext';
import { loadSlots } from '../../../modules/api';
import { Option, OptionType, Slot, SlotStatus, Unit } from '../../../modules/types';

type Props = {
  unit: Unit;
  lineItems: LineItems;
  options: Option[];
  icon?: JSX.Element;
  lineItemErrors: Slot[];
  optionErrors: { slotId: string; optionId: number }[];
};

export default function CartLineItems({
  lineItems,
  lineItemErrors,
  options,
  optionErrors,
  unit,
  icon = <ChevronDown className="cart__slot-icon cart__slot-icon--toggle" />,
}: Props): JSX.Element {
  const { getSlotPrice, toggleSlot, getOptionsPrice, toggleSlotOption } = useContext(OrderContext);
  const { formatPrice, formatDateTimeRange } = useContext(GlobalContext);

  const [expandedSlots, setExpandedSlots] = useState<string[]>([]);

  const lineItemsList: Array<JSX.Element> = [];

  function toggleSlotOptions(slotId: string): void {
    if (lineItemErrors.find((slot) => slot.id == slotId)) return;
    const reducedList =
      expandedSlots.filter((item) => {
        return item !== slotId;
      }) || [];

    if (reducedList.length === expandedSlots.length) {
      reducedList.push(slotId);
    }

    setExpandedSlots(reducedList);
  }

  const sortedLineItems = Array.from(lineItems.keys()).sort((a, b) => a.from.diff(b.from).toMillis());
  const earliestSlot = sortedLineItems.shift();
  const latestSlot = sortedLineItems.pop();

  const [optionsSlots, setOptionsSlots] = useState<Map<Option, Slot[]>>(new Map());

  useEffect(() => {
    options
      .filter((o) => o.type == OptionType.Slot && o.unitIds?.includes(unit.id))
      .forEach((option) => {
        loadSlots((slots) => setOptionsSlots(new Map(optionsSlots.set(option, slots))), {
          unitId: option.extra.unitId,
          from: earliestSlot?.from,
          to: latestSlot && latestSlot?.from !== earliestSlot?.from ? latestSlot?.to : earliestSlot?.from.endOf('day'),
        });
      });
  }, []);

  lineItems.forEach((lineItemsOptions, slot) => {
    const currentOptionSlots = new Map(
      Array.from(optionsSlots).map(([option, slots]) => {
        return [
          option,
          slots.filter((s) => {
            return s.from >= slot.from && s.to <= slot.to && s.status === SlotStatus.Available;
          }),
        ];
      }),
    );

    const slotHasError = lineItemErrors.includes(slot);
    const slotOptionErrors = optionErrors.filter((o) => o.slotId === slot.id);
    const slotOptions = options.filter((o) => o.perSlot);
    const slotPrice = getSlotPrice(slot);

    lineItemsList.push(
      <li
        key={slot.id}
        className={classNames('cart__slot', {
          'cart__slot--expanded': expandedSlots.includes(slot.id) && !slotHasError,
          'cart__slot--error': slotHasError,
        })}
      >
        {slotHasError && <AlertCircle className="cart__slot-icon cart__slot-icon--error" />}
        {!slotHasError && (
          <button
            className="button button--clear cart__slot-options-toggle-button"
            onClick={() => toggleSlotOptions(slot.id)}
          >
            {icon}
          </button>
        )}
        <span className="cart__slot-range" onClick={() => toggleSlotOptions(slot.id)}>
          <span>{formatDateTimeRange(slot.from, slot.to.plus(1000))}</span>
          {slotPrice.discount !== undefined && slotPrice.discount > 0 && (
            <span className="cart__slot-discount">-{slotPrice.discount}%</span>
          )}
        </span>
        <span className="cart__slot-price">
          <span className="cart__slot-price-base">{formatPrice(slotPrice)}</span>
        </span>
        <button
          className="button button--clear cart__slot-toggle-button"
          title="Скасувати"
          onClick={() => toggleSlot(slot)}
        >
          <Trash2 className="cart__slot-icon cart__slot-icon--remove" />
        </button>
        {lineItemsOptions.size > 0 && (!expandedSlots.includes(slot.id) || slotHasError) && (
          <span className="cart__slot-options-list">
            <span className="cart__slot-options-list-options">
              Додатково:{' '}
              {Array.from(lineItemsOptions)
                .map(([option]) => (
                  <span
                    className={classNames('cart__slot-options-list-option', {
                      'cart__slot-options-list-option--error':
                        typeof slotOptionErrors.find((e) => e.optionId === option.id) !== 'undefined',
                    })}
                    key={`${slot.id}_${option.id}`}
                  >
                    {option.name}
                  </span>
                ))
                .reduce((result, item) => (
                  <>
                    {result}
                    {', '}
                    {item}
                  </>
                ))}
            </span>
            <span className="cart__slot-options-list-price">
              <span>{formatPrice({ value: getOptionsPrice(lineItemsOptions) })}</span>
            </span>
          </span>
        )}
        {slotOptions.length > 0 && lineItemsOptions.size === 0 && !expandedSlots.includes(slot.id) && (
          <span className="cart__slot-options-toggle" onClick={() => toggleSlotOptions(slot.id)}>
            <span>Обрати додаткові послуги</span>
          </span>
        )}
        {slotOptions.length > 0 && (
          <CartOptions
            className="cart__slot-options"
            itemOptions={lineItemsOptions}
            optionsSlots={currentOptionSlots}
            parentId={slot.id}
            options={slotOptions}
            optionErrors={slotOptionErrors}
            onToggleOption={(option, value) => toggleSlotOption(slot, option, value)}
          />
        )}
      </li>,
    );
  });

  return <ul className="cart__slots-list">{lineItemsList}</ul>;
}
