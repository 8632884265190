import './style.scss';

import React, { createContext } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { loadSlots } from '../../modules/api';
import { Slot, Unit } from '../../modules/types';

import Labels from './Labels';
import Header from './Header';
import Body from './Body';
import Spinner from '../Spinner/index';

type Props = {
  unit: Unit;
};

type CalendarContext = {
  from: DateTime;
  to: DateTime;
  unit: Unit;
  slots?: Slot[];
};

export const CalendarContext = createContext<CalendarContext>({} as CalendarContext);

export default function Calendar({ unit }: Props): JSX.Element {
  const params = useParams<{ year?: string | undefined; week?: string | undefined }>();

  let from: DateTime;
  let to: DateTime;
  const now = DateTime.now();

  if (params.year && params.week) {
    const date = DateTime.fromObject({
      weekYear: parseInt(params.year),
      weekNumber: parseInt(params.week),
      weekday: 1,
    });
    from = date.startOf('week');
    to = date.endOf('week');
  } else {
    from = now.startOf('week');
    to = now.endOf('week');
  }

  const [slots, setSlots] = useState<Slot[]>();
  const [isSlotsLoading, setIsSlotsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsSlotsLoading(true);
    loadSlots(
      (slots) => {
        setIsSlotsLoading(false);
        setSlots(slots);
      },
      { unitId: unit.id, from, to },
    );
  }, [unit.id, from.weekNumber]);

  return (
    <CalendarContext.Provider
      value={{
        from,
        to,
        slots,
        unit,
      }}
    >
      <div className="unit-calendar">
        <Header />

        <div className="unit-calendar__content">
          {slots && (
            <>
              <Labels />
              <Body />
            </>
          )}
          {(!slots || isSlotsLoading) && (
            <div
              className={classNames('loading', {
                'loading--days': !slots,
                'loading--days-cover': slots && isSlotsLoading,
              })}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </CalendarContext.Provider>
  );
}
