import React, { useContext } from 'react';
import { CalendarContext } from '.';

export default function Labels(): JSX.Element {
  const labels: Array<JSX.Element> = [];

  const { unit } = useContext(CalendarContext);
  const [, minute] = unit.opensAt.split(':');

  for (let hour = 0; hour < 24; hour++) {
    let nextHour = String(hour + 1);
    nextHour === '24' && (nextHour = '00');

    labels.push(
      <div key={hour} className="unit-calendar__label">
        {String(hour).padStart(2, '0')}:{minute} – {nextHour.padStart(2, '0')}:{minute}
      </div>,
    );
  }

  return <div className="unit-calendar__labels">{labels}</div>;
}
