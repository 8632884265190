import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { conf } from './config';
import {
  apiMethod,
  Slot,
  slotFilters,
  SlotStatus,
  Price,
  Unit,
  Option,
  OrderData,
  SubmittedOrderData,
  Error,
  Settings,
  Discount,
  DiscountType,
  DiscountConditionType,
} from './types';

async function callApi(
  url: string,
  params?: Record<string, unknown>,
  method: apiMethod = apiMethod.get,
): Promise<AxiosResponse> {
  const request = axios.create({
    baseURL: conf('REACT_APP_API_URL').replace(/\/$/, ''),
  });

  switch (method) {
    case apiMethod.get:
      return request.get(url, { params });
    case apiMethod.post:
      return request.post(url, params);
  }
}

export function loadSettings(onLoad: (settings: Settings) => void): void {
  callApi(`settings`).then((response) => {
    onLoad(response.data);
  });
}

export function loadDiscounts(onLoad: (settings: Discount[]) => void): void {
  onLoad([
    {
      units: [1, 2, 9],
      rules: {
        type: DiscountType.Percentage,
        value: 10,
        conditions: [
          {
            type: DiscountConditionType.OrderItemsCount,
            count: 3,
            units: [1, 2, 9],
          },
        ],
      },
    },
    {
      units: [1, 2, 9],
      rules: {
        type: DiscountType.Percentage,
        value: 5,
        conditions: [
          {
            type: DiscountConditionType.OrderItemsCount,
            count: 2,
            units: [1, 2, 9],
          },
        ],
      },
    },
  ]);
}

export function loadSlots(onLoad: (slots: Slot[]) => void, filters?: slotFilters): void {
  const processSlots = (
    data: [
      {
        unitId: number;
        status: SlotStatus;
        from: string;
        to: string;
        price: Price;
      },
    ],
  ): Slot[] => {
    return data.map((slot) => {
      return {
        ...slot,
        id: `${slot.unitId}_${DateTime.fromISO(slot.from).toMillis()}`,
        from: DateTime.fromISO(slot.from),
        to: DateTime.fromISO(slot.to),
      };
    });
  };

  callApi(`slots`, {
    unitId: filters?.unitId,
    from: filters?.from?.toISO(),
    to: filters?.to?.toISO(),
  }).then((response) => {
    onLoad(processSlots(response.data));
  });
}

export function loadUnits(onLoad: (slots: Unit[]) => void): void {
  callApi(`units`).then((response) => {
    onLoad(response.data);
  });
}

export function loadOptions(onLoad: (options: Option[]) => void): void {
  callApi(`options`).then((response) => {
    onLoad(response.data);
  });
}

export function postOrder(
  orderData: OrderData,
  onSuccess: (order: SubmittedOrderData, payment_url: string) => void,
  onError: (code: number, errors: Error[]) => void,
): void {
  callApi('order', orderData, apiMethod.post)
    .then((response) => {
      onSuccess(response.data.order, response.data.payment_url);
    })
    .catch((error) => {
      onError(error.response.status, error.response.data.errors);
    });
}
