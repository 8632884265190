import React, { useContext } from 'react';

import LineItems from './LineItems';
import CartOptions from './Options';
import GlobalOption from './GlobalOption';
import { Clock, Users } from 'react-feather';

import { GlobalContext } from '../../../context/GlobalContext';
import { OrderContext } from '../../../context/OrderContext';
import { OptionType, Slot, UnitType } from '../../../modules/types';

type Props = {
  lineItemErrors: Slot[];
  optionErrors: { slotId: string; optionId: number }[];
};

export default function CartContent({ lineItemErrors, optionErrors }: Props): JSX.Element {
  const { units, options, settings, formatPrice } = useContext(GlobalContext);
  const { lineItems, orderOptions, getLineItemsByUnit, getTotalPrice, toggleOrderOption } = useContext(OrderContext);

  const cartUnits: Array<JSX.Element> = [];

  const globalOptions: Array<JSX.Element> = [];

  const orderHasEarlyOrLateSlot =
    settings &&
    Array.from(lineItems).filter(
      ([slot]) => slot.from.hour < settings.schedule.from || slot.from.hour >= settings.schedule.to,
    ).length > 0;

  options
    .filter((option) => option.type === OptionType.Unit)
    .forEach((option) => {
      globalOptions.push(<GlobalOption option={option} key={option.id} lineItemErrors={lineItemErrors} />);
    });

  units
    ?.filter((u) => u.type === UnitType.Primary)
    .forEach((unit) => {
      const unitLineItems = getLineItemsByUnit(unit);
      const unitOptions = options.filter((o) => o.unitIds?.includes(unit.id));

      if (unitLineItems.size > 0) {
        cartUnits.push(
          <div className="cart__unit" key={`${unit.id}-unit-wrapper`}>
            <div className="cart__unit-name" key={`${unit.id}-unit-name`}>
              {unit.name}
            </div>
            <LineItems
              unit={unit}
              lineItems={unitLineItems}
              options={unitOptions}
              lineItemErrors={lineItemErrors}
              optionErrors={optionErrors}
            />
            <CartOptions
              className="cart__unit-options"
              parentId={unit.id}
              itemOptions={orderOptions}
              options={unitOptions.filter((o) => !o.perSlot)}
              optionErrors={optionErrors}
              onToggleOption={toggleOrderOption}
            />
          </div>,
        );
      }
    });

  return (
    <>
      {lineItems.size > 0 && (
        <>
          <div className="cart__units">{cartUnits}</div>
          <div className="cart__options">{globalOptions}</div>
          <div className="cart__total">
            {settings?.visitors.message && (
              <div className="cart__message cart__message--visitors">
                <Users className="cart__message-icon" />
                <div className="cart__message-text" dangerouslySetInnerHTML={{ __html: settings.visitors.message }} />
              </div>
            )}
            {orderHasEarlyOrLateSlot && settings?.schedule.message && (
              <div className="cart__message cart__message--time">
                <Clock className="cart__message-icon" />
                <div className="cart__message-text" dangerouslySetInnerHTML={{ __html: settings.schedule.message }} />
              </div>
            )}
            Загалом: {formatPrice({ value: getTotalPrice() })}
          </div>
        </>
      )}
    </>
  );
}
