import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { ChevronLeft, ChevronRight } from 'react-feather';

import { Unit } from '../../modules/types';
import { CalendarContext } from '../Calendar';
import { GlobalContext } from '../../context/GlobalContext';

export default function Header(): JSX.Element {
  const { from, to, unit } = useContext(CalendarContext);
  const { weekYear, weekNumber } = from;
  const now = DateTime.now();
  const history = useHistory();
  const currentDate = DateTime.fromObject({
    weekYear,
    weekNumber,
  });

  const { settings } = useContext(GlobalContext);

  function datesInterval(from: DateTime, to: DateTime) {
    if (from.month === to.month) {
      return (
        <>
          {from.day}—{to.toFormat('d MMMM', { locale: settings?.locale })}
        </>
      );
    } else {
      return (
        <>
          {from.toFormat('d MMMM', { locale: settings?.locale })}—{to.toFormat('d MMMM', { locale: settings?.locale })}
        </>
      );
    }
  }

  function goToWeek(unit: Unit, year: number, week: number) {
    history.push(`/${unit.slug}/${year}/${week}`);
  }

  function nextWeek() {
    if (weekNumber >= currentDate.weeksInWeekYear) {
      goToWeek(unit, weekYear + 1, 1);
    } else {
      goToWeek(unit, weekYear, weekNumber + 1);
    }
  }
  function prevWeek() {
    if (weekNumber === 1 && weekYear > now.startOf('week').year) {
      const prevYear = DateTime.fromObject({
        weekYear: weekYear - 1,
        weekNumber: 1,
      });
      goToWeek(unit, prevYear.year, prevYear.weeksInWeekYear);
    } else if (weekNumber > now.weekNumber || weekYear > now.startOf('week').year) {
      goToWeek(unit, weekYear, weekNumber - 1);
    }
  }

  return (
    <div className="unit-calendar__head">
      <div className="unit-calendar__title">{unit.name}</div>
      <div className="unit-calendar__pager pager">
        <button
          className={classNames('pager__button', {
            'pager__button--disabled': weekYear <= now.startOf('week').year && weekNumber <= now.weekNumber,
          })}
          onClick={prevWeek}
        >
          <ChevronLeft />
        </button>
        <span className="pager__interval">
          {datesInterval(from, to)}
          {weekYear !== now.year && <>, {weekYear}</>}
        </span>
        <button className="pager__button" onClick={nextWeek}>
          <ChevronRight />
        </button>
      </div>
    </div>
  );
}
