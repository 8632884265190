import React, { useContext } from 'react';
import { DateTime } from 'luxon';

import { Slot } from '../../modules/types';
import { CalendarContext } from '../Calendar';

import Day from './Day';

export default function Body(): JSX.Element {
  const { from, to, slots } = useContext(CalendarContext);

  function findDaySlots(date: DateTime, slots: Slot[] | undefined): Slot[] | undefined {
    return slots?.filter((el) => el.from.day == date.day);
  }

  function days(from: DateTime, to: DateTime): JSX.Element[] {
    const days: Array<JSX.Element> = [];
    while (from < to) {
      days.push(
        <Day slots={findDaySlots(from, slots)} date={from} key={from.toMillis()} className="unit-calendar__day" />,
      );
      from = from.plus({ days: 1 });
    }
    return days;
  }

  return <div className="unit-calendar__days">{days(from, to)}</div>;
}
