import React, { useContext } from 'react';
import { Slot, SlotStatus } from '../../../modules/types';
import classNames from 'classnames';
import { Lock, Unlock, PlusSquare, CheckSquare } from 'react-feather';
import { OrderContext } from '../../../context/OrderContext';
import { DateTime } from 'luxon';

type Props = {
  slot: Slot;
};

export default function DaySlot({ slot }: Props): JSX.Element {
  const { toggleSlot, hasSlot } = useContext(OrderContext);

  function status(slot: Slot): JSX.Element | null {
    switch (slot.status) {
      case SlotStatus.Available:
        return hasSlot(slot) ? (
          <>
            <CheckSquare className="slot__icon" />
            <span className="slot__label">Обрано</span>
          </>
        ) : (
          <PlusSquare className="slot__icon" />
        );
      case SlotStatus.Reserved:
        return (
          <>
            <Unlock className="slot__icon" />
            <span className="slot__label">Резерв</span>
          </>
        );
      case SlotStatus.Booked:
        return (
          <>
            <Lock className="slot__icon" />
            <span className="slot__label">Бронь</span>
          </>
        );
      case SlotStatus.NotAvailable:
        if (slot.from < DateTime.now()) {
          return null;
        }
        return (
          <>
            <Lock className="slot__icon" />
            <span className="slot__label">Недоступно</span>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <label
      className={classNames('day__slot', 'slot', `slot--${slot.status.toLowerCase()}`, {
        'slot--discount': slot.price.discount !== undefined && slot.price.discount > 0,
        'slot--selected': hasSlot(slot),
      })}
    >
      <input
        type="checkbox"
        disabled={slot.status !== SlotStatus.Available}
        onChange={() => toggleSlot(slot)}
        checked={hasSlot(slot)}
      />
      {status(slot)}
      {slot.status === SlotStatus.Available && slot.price.discount !== undefined && slot.price.discount > 0 && (
        <span className="slot__discount">
          <span className="discount">-{slot.price.discount}%</span>
        </span>
      )}
    </label>
  );
}
