import './style.scss';

import React, { FormEvent, SyntheticEvent, useContext, useState } from 'react';
import { Frown, ShoppingBag } from 'react-feather';

import CartContent from './Content';
import { OrderContext } from '../../../context/OrderContext';
import { postOrder } from '../../../modules/api';
import { Error, Slot, ErrorType, SubmittedOrderData, OrderData } from '../../../modules/types';
import { DateTime } from 'luxon';
import Spinner from '../../Spinner/index';
import { GlobalContext } from '../../../context/GlobalContext';
import classNames from 'classnames';

type Props = {
  showConfirmation: (order: SubmittedOrderData, url: string) => void;
};

export default function Cart({ showConfirmation }: Props): JSX.Element {
  const { lineItems, orderOptions, clearOrder } = useContext(OrderContext);
  const { settings } = useContext(GlobalContext);

  const orderLineItems = Array.from(lineItems);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);

    const options = Array.from(orderOptions).map(([option, value]) => {
      return { id: option.id, value };
    });

    const lineItems = orderLineItems.map(([slot, options]) => {
      return {
        unitId: slot.unitId,
        start: slot.from.toISO(),
        options: Array.from(options).map(([option, value]) => {
          return { id: option.id, value };
        }),
      };
    });

    const orderData: OrderData = {
      ...orderInfo,
      options,
      lineItems,
    };

    postOrder(
      orderData,
      (order, url) => {
        setIsLoading(false);
        clearOrder();
        showConfirmation(order, url);
      },
      processOrderErrors,
    );
  };

  const [lineItemErrors, setLineItemsErrors] = useState<Slot[]>([]);
  const [optionErrors, setOptionErrors] = useState<{ slotId: string; optionId: number }[]>([]);
  const [submitError, setSubmitError] = useState<{ title: string; text: string }>();
  const [formErrors, setFormsErrors] = useState<string[]>([]);

  function processOrderErrors(status: number, errors: Error[]): void {
    setIsLoading(false);
    setLineItemsErrors([]);
    setFormsErrors([]);
    switch (status) {
      case 406:
        if (errors) {
          errors.forEach((error) => {
            switch (error.error) {
              case ErrorType.SlotPast:
              case ErrorType.SlotUnavailable:
                if (error?.start && error?.unitId) {
                  const errorSlotId = `${error.unitId}_${DateTime.fromISO(error.start).toMillis()}`;
                  const errorSlot = orderLineItems.find(
                    ([slot]) => slot.id === errorSlotId || slot.id.split('::')[0] === errorSlotId,
                  );
                  if (errorSlot) {
                    setLineItemsErrors((lineItemErrors) => [...lineItemErrors, errorSlot[0]]);
                  }
                }
                setSubmitError({
                  title: 'Часть времени уже недоступна',
                  text: 'Пожалуйста, измените вашу бронь',
                });
                break;
              case ErrorType.OptionUnavailable:
                if (error.start && error.unitId && error.optionId) {
                  const slotId = `${error.unitId}_${DateTime.fromISO(error.start).toMillis()}`;
                  setOptionErrors((optionErrors) => [
                    ...optionErrors,
                    { slotId: slotId, optionId: error.optionId || 0 },
                  ]);
                }
                setSubmitError({
                  title: 'Часть выбранных услуг недоступна',
                  text: 'Пожалуйста, измените вашу бронь',
                });
                break;
              case ErrorType.CouponUnavailable:
                setSubmitError({
                  title: 'Некорректный номер сертификата',
                  text: 'Пожалуйста, проверьте введенный код. Возможно сертификат истек или уже был использован',
                });
                setFormsErrors(['coupon']);
                break;
            }
          });
        }
        break;
      default:
        setSubmitError({
          title: 'Что-то пошло не так',
          text: 'Пожалуйста, попробуйте отправить бронь еще раз',
        });
    }
  }

  const [orderInfo, setOrderInfo] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    comment: '',
    couponCode: '',
  });

  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);

  function updateOrderInfo(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = e.currentTarget;
    setOrderInfo({ ...orderInfo, [name]: value });
  }

  return (
    <div className="cart">
      {lineItems.size > 0 ? (
        <>
          <div className="cart__order-details">
            {submitError && (
              <div className="cart__error">
                <Frown className="cart__error-icon" />
                <div className="cart__error-message">
                  <div className="cart__error-title">{submitError.title}</div>
                  <div className="cart__error-text">{submitError.text}</div>
                </div>
              </div>
            )}
            <CartContent lineItemErrors={lineItemErrors} optionErrors={optionErrors} />
          </div>
          <form className="cart__order-info-form" onSubmit={onSubmit}>
            <div className={classNames('form-item', { 'form-item--error': formErrors.includes('firstName') })}>
              <label className="form-label" htmlFor="first-name-field">
                Ім&apos;я<sup>*</sup>
              </label>
              <input
                name="firstName"
                id="first-name-field"
                className="form-text"
                type="text"
                required
                onChange={updateOrderInfo}
                value={orderInfo.firstName}
              />
            </div>
            <div className={classNames('form-item', { 'form-item--error': formErrors.includes('lastName') })}>
              <label className="form-label" htmlFor="last-name-field">
                Прізвище<sup>*</sup>
              </label>
              <input
                name="lastName"
                id="last-name-field"
                className="form-text"
                type="text"
                required
                onChange={updateOrderInfo}
                value={orderInfo.lastName}
              />
            </div>
            <div className={classNames('form-item', { 'form-item--error': formErrors.includes('phone') })}>
              <label className="form-label" htmlFor="phone-field">
                Телефон<sup>*</sup>
              </label>
              <input
                name="phone"
                id="phone-field"
                className="form-text"
                type="text"
                required
                onChange={updateOrderInfo}
                value={orderInfo.phone}
              />
            </div>
            <div className={classNames('form-item', { 'form-item--error': formErrors.includes('email') })}>
              <label className="form-label" htmlFor="email-field">
                Email<sup>*</sup>
              </label>
              <input
                name="email"
                id="email-field"
                className="form-text"
                type="email"
                required
                onChange={updateOrderInfo}
                value={orderInfo.email}
              />
            </div>
            <div className="form-item">
              <label className="form-label" htmlFor="comment-field">
                Додаткові побажання
              </label>
              <textarea
                name="comment"
                id="comment-field"
                className="form-text"
                onChange={updateOrderInfo}
                value={orderInfo.comment}
              />
            </div>
            {settings?.couponsEnabled && (
              <div className={classNames('form-item', { 'form-item--error': formErrors.includes('coupon') })}>
                <label className="form-label" htmlFor="coupon-field">
                  Подарунковий сертифікат
                </label>
                <input
                  name="couponCode"
                  id="coupon-field"
                  type="text"
                  className="form-text"
                  onChange={updateOrderInfo}
                  value={orderInfo.couponCode}
                />
              </div>
            )}
            {settings?.rules && (
              <div className="form-item">
                <label className="form-label form-label--checkbox">
                  <input
                    className="form-checkbox"
                    type="checkbox"
                    onChange={(e) => setIsAgreedToTerms(e.currentTarget.checked)}
                    checked={isAgreedToTerms}
                  />
                  <span>
                    із{' '}
                    <a target="blank" href={settings.rules}>
                      правилами та умовами оренди
                    </a>{' '}
                    та{' '}
                    <a target="blank" href={settings.rules}>
                      договором публічної оферти
                    </a>{' '}
                    ознайомлений
                  </span>
                </label>
              </div>
            )}

            <button className="button cart__submit-button" type="submit" disabled={!isAgreedToTerms || isLoading}>
              {!isLoading && <>Забронювати</>}
              {isLoading && (
                <>
                  <Spinner /> Обробляємо замовлення
                </>
              )}
            </button>
          </form>
        </>
      ) : (
        <div className="cart__empty">
          <ShoppingBag />
          Нічого не вибрано. <br />
          Додайте щось до замовлення
        </div>
      )}
    </div>
  );
}
