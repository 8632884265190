import React, { useContext, useState, useEffect } from 'react';

import { DateTime } from 'luxon';
import classNames from 'classnames';
import DatePicker from 'react-date-picker';
import { User, UserCheck, Calendar, X, UserPlus, Trash2, AlertCircle, ChevronDown } from 'react-feather';

import { GlobalContext } from '../../../context/GlobalContext';
import { OrderContext } from '../../../context/OrderContext';

import { loadSlots } from '../../../modules/api';
import { Option, Slot, UnitType } from '../../../modules/types';
import LineItems from './LineItems';
import Spinner from '../../Spinner';

type Props = {
  option: Option;
  lineItemErrors: Slot[];
};

export default function GlobalOption({ option, lineItemErrors }: Props): JSX.Element {
  const { settings, units, formatPrice } = useContext(GlobalContext);
  const { lineItems, toggleSlot, getLineItemsByUnit, getSlotsPrice } = useContext(OrderContext);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [slotsExpanded, setSlotsExpanded] = useState<boolean>(false);
  const [optionSlots, setOptionSlots] = useState<Slot[]>();
  const [selectedDate, setSelectedDate] = useState<DateTime>();
  const [isSlotsLoading, setIsSlotsLoading] = useState<boolean>(false);
  const [isWindowOpened, setIsWindowOpened] = useState<boolean>(false);

  const unit = units?.find((u) => u.id === option.extra.unitId);
  const unitLineItems = unit ? getLineItemsByUnit(unit) : undefined;

  const processedLineItemsErrors: Slot[] = [];

  const richUI = false;

  unitLineItems?.forEach((o, slot) => {
    const slotId = slot.id.split('::')[0];

    const slotErrors = lineItemErrors.filter((s) => {
      const sId = s.id.split('::')[0];
      return sId === slotId;
    });

    for (let index = 1; index <= slotErrors.length; index++) {
      processedLineItemsErrors.push(slot);
    }
  });

  useEffect(() => {
    if (selectedDate) {
      setIsSlotsLoading(true);
      setOptionSlots(undefined);
      loadSlots(
        (slots) => {
          setOptionSlots(slots);
          setIsSlotsLoading(false);
        },
        {
          unitId: option.extra.unitId,
          from: selectedDate.startOf('day'),
          to: selectedDate.endOf('day'),
        },
      );
    } else {
      setOptionSlots(undefined);
    }
  }, [selectedDate]);

  const allDates: DateTime[] = [];

  lineItems.forEach((options, slot) => {
    const date = slot.from.startOf('day');
    const unit = units?.find((u) => u.id === slot.unitId);
    if (!allDates.find((d) => date.equals(d)) && unit && unit.type === UnitType.Primary) {
      allDates.push(date);
    }
  });

  function selectedSeats(slot: Slot): number {
    return Array.from(lineItems).filter(([s]) => s.id.split('::')[0] === slot.id).length;
  }

  function seats(slot: Slot): JSX.Element[] {
    const seats: JSX.Element[] = [];
    const selected = selectedSeats(slot);

    if (slot.count) {
      for (let index = 1; index <= slot.count; index++) {
        if (index <= selected) {
          seats.push(
            <UserCheck
              className="cart-option-global__slot-icon cart-option-global__slot-icon--checked"
              key={`${slot.id}::${index}`}
            />,
          );
        } else {
          seats.push(<User className="cart-option-global__slot-icon" key={`${slot.id}::${index}`} />);
        }
      }
    }
    return seats;
  }

  function slotControls(slot: Slot): JSX.Element | undefined {
    return selectedSeats(slot) > 0 ? (
      <span className="cart-option-global__slot-controls">
        <span className="cart-option-global__slot-count">Місць:</span>
        <button className="button" disabled={selectedSeats(slot) === 1} onClick={() => removeSeat(slot)}>
          <span>&minus;</span>
        </button>
        <span className="cart-option-global__slot-count">
          {richUI ? seats(slot) : selectedSeats(slot)} / {slot.count}
        </span>
        <button className="button" disabled={selectedSeats(slot) === slot.count} onClick={() => addSeat(slot)}>
          <span>+</span>
        </button>
      </span>
    ) : !slot.count ? (
      <>немає місць</>
    ) : undefined;
  }

  function addSeat(slot: Slot): void {
    if (slot.count && slot.count - selectedSeats(slot) > 0) {
      const seatSlot = Object.assign({}, slot);
      seatSlot.id += '::' + selectedSeats(slot);
      toggleSlot(seatSlot);
    }
  }
  function removeSeat(slot: Slot): void {
    if (selectedSeats(slot) > 0) {
      const slotSeats = Array.from(lineItems).filter(([s]) => s.id.split('::')[0] === slot.id);
      toggleSlot(slotSeats[slotSeats.length - 1][0]);
    }
  }

  function toggleSeats(slot: Slot): boolean {
    if (!selectedSeats(slot)) {
      addSeat(slot);
    } else {
      const slotSeats = Array.from(lineItems).filter(([s]) => s.id.split('::')[0] === slot.id);
      slotSeats.forEach((seat) => {
        toggleSlot(seat[0]);
      });
    }
    return false;
  }

  function clearAllSeats(): void {
    unitLineItems?.forEach((options, slot) => toggleSlot(slot));
  }

  function slotItem(slot: Slot): JSX.Element {
    return (
      <div
        className={classNames('cart-option-global__slot', {
          'cart-option-global__slot--available': slot.count && slot.count > 0,
        })}
        key={slot.id}
        onClick={() => !selectedSeats(slot) && addSeat(slot)}
      >
        <input
          type="checkbox"
          checked={selectedSeats(slot) > 0}
          onChange={() => toggleSeats(slot)}
          onClick={(e) => e.stopPropagation()}
          disabled={!slot.count}
        />
        <span className="cart-option-global__slot-time">
          {slot.from.toFormat('HH:mm')} – {slot.to.plus({ second: 1 }).toFormat('HH:mm')}
        </span>
        {slotControls(slot)}
      </div>
    );
  }

  const hasErrors = processedLineItemsErrors.length;

  return (
    <div
      className={classNames('cart-option-global', {
        'cart-option-global--relative': !optionSlots,
        'cart-option-global--with-window': isWindowOpened,
      })}
    >
      <div className="cart-option-global__name cart__unit-name">
        <div>{option.name}</div>
        <button
          className="button button--small cart-option-global__button"
          onClick={() => setIsWindowOpened(!isWindowOpened)}
        >
          <UserPlus className="cart-option-global__button-icon" />
          Додати
        </button>
      </div>
      {!unitLineItems?.size && (
        <div className="cart-option-global__empty">
          <div className="cart-option-global__empty-text">Ви не обрали гримерну</div>
        </div>
      )}
      {isWindowOpened && (
        <div
          className={classNames('cart-option-global__window', {
            'cart-option-global__window--loaded': optionSlots,
          })}
        >
          <button className="modal__close" onClick={() => setIsWindowOpened(false)}>
            <X />
          </button>
          <div className="cart-option-global__calendar">
            <div className="cart-option-global__dates">
              <div className="cart-option-global__dates-selector">
                <div className="cart-option-global__label">Оберіть дату:</div>
                <DatePicker
                  value={selectedDate?.toJSDate()}
                  locale={settings?.locale}
                  calendarIcon={<Calendar />}
                  clearIcon={null}
                  minDate={new Date()}
                  format="dd.MM.yyyy"
                  onChange={(d: Date) => {
                    setSelectedDate(d ? DateTime.fromJSDate(d) : undefined);
                    setSlotsExpanded(false);
                  }}
                />
                {allDates.map((date) => (
                  <div
                    className="cart-option-global__date"
                    key={date.toFormat('dd.MM.yyyy')}
                    onClick={() => {
                      setSelectedDate(date);
                      setSlotsExpanded(false);
                    }}
                  >
                    <span>{date.toFormat('dd.MM.yyyy')}</span>
                  </div>
                ))}
              </div>
            </div>
            {isSlotsLoading && <Spinner />}
            {optionSlots && (
              <>
                <div className="cart-option-global__label">Оберіть час:</div>
                <div className="cart-option-global__slots">
                  {!slotsExpanded && settings && (
                    <div className="cart-option-global__slots-toggler" onClick={() => setSlotsExpanded(true)}>
                      <span>показати час з 00:00 до {settings.schedule.from}:00</span>
                    </div>
                  )}
                  {optionSlots
                    ?.filter((slot) => !settings || slotsExpanded || slot.from.hour > settings.schedule.from)
                    .map((slot) => slotItem(slot))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {unit && !!unitLineItems?.size && (
        <>
          <div
            className={classNames('cart-option-global__summary', {
              'cart-option-global__summary--expanded': expanded && !hasErrors,
              'cart-option-global__summary--error': hasErrors,
            })}
          >
            {!!hasErrors && <AlertCircle className="cart__slot-icon cart__slot-icon--error" />}
            {!hasErrors && (
              <button
                className="button button--clear cart__slot-options-toggle-button"
                onClick={() => setExpanded(!expanded)}
              >
                {<ChevronDown className="cart__slot-icon cart__slot-icon--toggle" />}
              </button>
            )}
            <div className="cart-option-global__summary-title" onClick={() => setExpanded(!expanded)}>
              Гримерна
            </div>
            <div className="cart-option-global__summary-price">
              {formatPrice({ value: getSlotsPrice(unitLineItems) })}
            </div>
            <button
              className="button button--clear cart__slot-toggle-button"
              title="Отменить время"
              onClick={() => {
                clearAllSeats();
              }}
            >
              <Trash2 className="cart__slot-icon cart__slot-icon--remove" />
            </button>
            {!expanded && (
              <span className="cart__slot-options-toggle" onClick={() => setExpanded(!expanded)}>
                <span>Налаштувати місця у гримерній</span>
              </span>
            )}
          </div>
          {expanded && (
            <LineItems
              unit={unit}
              lineItems={unitLineItems}
              options={[]}
              lineItemErrors={processedLineItemsErrors}
              optionErrors={[]}
              icon={<User className="cart-option-global__icon" />}
            />
          )}
        </>
      )}
    </div>
  );
}
